import React from 'react';
import styled from '@emotion/styled';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { DownArrow } from '../SVG';
import { Text, devices, Spacing } from '../common';
import Markdown from '../Markdown';
import type { Typography } from '../../theme';

type Props = {
  header: string;
  text: string;
  additionalInfo?: React.ReactNode;
};

export default function SimpleAccordion({
  header,
  text,
  additionalInfo,
}: Props) {
  return (
    <RootContainer>
      <CustomAccordion>
        <AccordionSummary
          expandIcon={<DownArrow />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Title variant="title-4">{header}</Title>
        </AccordionSummary>
        <Details>
          {additionalInfo && (
            <>
              {additionalInfo}
              <Spacing axis="y" amount="medium" />
            </>
          )}
          {text && <RichTextContent>{text}</RichTextContent>}
        </Details>
      </CustomAccordion>
    </RootContainer>
  );
}

const RootContainer = styled.div`
  width: 100%;
  font-size: ${(props) => props.theme.sizing.default};
  margin-bottom: ${(props) => props.theme.spacing.medium};
`;

const CustomAccordion = styled(Accordion)`
  padding: 20px;
`;

const Title = styled(Text)`
  @media ${devices.mobileXL} {
    width: 100%;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
`;

const Details = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
`;

export const RichTextContent = styled(Markdown)<{
  width?: string;
  quote?: boolean;
  largeText?: boolean;
  blog?: boolean;
  blogCard?: boolean;
  variant?: Typography;
}>`
  width: ${(props) => props.width && props.width};
  max-width: 100%;
  line-height: 24px;
  margin-top: ${(props) => props.quote && '-24px'};
  font-family: 'quatro', sans-serif;
  font-size: ${(props) => (props.largeText ? '20px' : '18px')};
  font-weight: ${(props) => props.largeText && '400'};
  margin: 24px 0px;
  ${(p) => p.variant && p.theme.typography[p.variant]}

  p + ul {
    & > li:first-of-type {
      margin-top: 0 !important;
    }

    & > li:last-of-type {
      margin-bottom: 0 !important;
    }

    & > li {
      font-size: 18px;
      margin: ${(p) => p.theme.spacing.large} 0px;
    }
  }

  p + code {
    ${(props) => props.theme.typography.body};
    font-family: 'quatro', sans-serif !important;
  }

  @media ${devices.mobileXL} {
    width: 100%;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
`;
